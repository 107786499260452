<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="导入销售订单">
    <div class="head-container">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{ btnName }}</el-button>
      </el-upload>
      <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
    </div>
    <div class="v" v-if="sucInfo">
      <span>
        导入结果：本次共成功导入
        <b class="fc-s">{{ sucInfo.success }}</b>&nbsp;
        条,失败
        <b class="fc-e">{{ sucInfo.errList.length }}</b>&nbsp;
        条
      </span>&nbsp;
      <span>错误详情</span>
      <ol class="bc-l lh-150" style="padding: 10px 20px 10px 40px;border-radius: var(--border-radius-base);margin: 10px 0 0 0;" v-if="sucInfo.errList && sucInfo.errList.length">
        <li v-for="e in sucInfo.errList">{{ e }}</li>
      </ol>
    </div>
  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {
      uploading: false,
      uploadUrl: config.hosts.request + "api/orderForm/import",
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      errList: [],
      sucInfo: null,
      errInfo: null,
    };
  },
  computed: {
    btnName() {
      return this.uploading ? "导入中" : "选择文件";
    },
  },
  methods: {
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {
      this.sucInfo = res;
      this.errList = res.errList;
      this.uploading = false;
      if (res.sucCount && res.sucCount > 0) {
        this.$parent.init();
      }
    },
    handleUploadError(err) {
      console.log(err);
      this.errInfo = err;
      this.uploading = false;
    },
    downloadTemplate() {
      this.downloadLoading = true;
      download("api/orderForm/template")
        .then((result) => {
          downloadFile(result, "销售订单模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    resetForm() {
      this.uploadHeaders.Authorization = "Bearer " + getToken();
      this.sucInfo = null;
      this.dialog = true;
    },
  },
};
</script>